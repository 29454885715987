/* ==================================================
Copyright year:

Updates copyright year with JS
===================================================*/
var currentYear  = new Date().getFullYear();
$('#copyright').text(currentYear);


/* ==================================================
Mobile nav display toggle:

Toggles animated display of main header nav
on small screens
===================================================*/
$('.nav-toggle').click(function(e) {
    e.preventDefault();
    var $mainNav = $('.page-header .small-nav');
    var $navToggle = $(this);

    $navToggle.toggleClass('active');
    $mainNav.toggleClass('open');
    $navToggle.children('.hamburger').toggleClass('active');

    if($mainNav.hasClass('open')) {
        Foundation.Motion.animateIn($mainNav, 'slide-in-right fast');
    } else {
        Foundation.Motion.animateOut($mainNav, 'slide-out-right fast');
    }
});

$('.small-nav .dropdown-link').click(function(e) {
  e.preventDefault();
  let $dropdownList = $(this).next('ul');

  if($(this).hasClass('open')) {
    $dropdownList.height(0);
    $(this).removeClass('open');
    return;
  }
  $(this).addClass('open');
  $dropdownList.height($dropdownList.prop("scrollHeight"));
});



/* ==================================================
Active Nav:

Adds the class of 'selected' to the anchor (if any)
that matches the current path.
===================================================*/

const path = location.pathname.substring(1);

if(path.split('/')[1]) {
  $(`nav a[href$="${path.split('/')[1]}/"]`).parent().addClass('selected');
  $(`nav a[href$="${path.split('/')[1]}/"]`).parents('.dropdown-child').addClass('selected');
}
else if(path.split('/')[0]) {
  $(`nav a[href$="${path.split('/')[0]}/"]`).parent().addClass('selected');
  $(`nav a[href$="${path}"]`).parents('.dropdown-child').addClass('selected');
}


/* ==================================================
Mobile Nav Dropdown List:

Toggles animated display of the next nav element
after clicking parent icon
===================================================*/

$('.dropdown .arrow-icon').on('click', function (e) {
  e.preventDefault();
  $(this).toggleClass('open');
  const trigger = $(this).parent().next('.list');

  const $toggleNext = new Foundation.Toggler(trigger, {
    animate: 'fade-in fade-out'
  });
  $(trigger).foundation('toggle');
});


/* ==================================================
Toggle element:

Toggles animated display of element with an id that
matches the 'data-toggle-trigger' of the clicked
element. The default animation is slide in and
slide out.
===================================================*/

$('a[data-toggle-trigger]').click(function (e) {
  e.preventDefault();
  const triggerId = $(this).data('toggle-trigger');
  const toggleItem = $(`#${triggerId}`);

  $(this).toggleClass('open');

  const $toggleElement = new Foundation.Toggler(toggleItem, {
    animate: 'animate-in animate-out',
  });

  toggleItem.foundation('toggle');
});


/* ==================================================
AJAX Form Submission:

Submits a BC form via AJX and then hides the form
after successful response and displays a thank
you message
===================================================*/
function ajaxWebformSubmit(options) {
  var settings = $.extend({
        formID : null,
        formWrapper: null,
        successMsg : 'Your message has been sent!',
        errorMsg : 'Sorry, looks like there was a problem. Please try again.'
    },options);

  $.ajax({
      url: $(settings.formID).attr("action") + "&JSON=1",
      data: $(settings.formID).serialize(),
      type: "POST"
    }).done(function(msg) {
      var formResponse = eval(msg);
        if (formResponse.FormProcessV2Response.success) {
          if (settings.successMsg) {
            $(settings.formWrapper).after("<div class=\"json-success\" style=\"display:none;\">" + settings.successMsg + "</div>");
          }
          $(settings.formWrapper).hide();
          $(".json-success").fadeIn(400);
        }
    }).fail(function(msg) {
      alert(settings.errorMsg + msg);
  });
}


/* ==================================================
Sliders:

Slick carousel setup and initialization
===================================================*/

// $('.hero .slider').slick({
//   autoplay: true,
//   autoplaySpeed: 7000,
//   pauseOnHover: true,
//   draggable: false,
// });


/* ==================================================
Magnific:

Magnific is used for lightbox style popups.

.image-link is for displaying larger versions of
images from a a link.

.video-link is for displaying videos from a link.
Common providers like Youtube and Vimeo will
stop playing when window is closed.
===================================================*/

$('.image-link').magnificPopup({ type: 'image' });
$('.gallery-item').magnificPopup({ type: 'image' });
$('.video-link').magnificPopup({ type: 'iframe' });
